@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  /* --gradient: linear-gradient(90deg, #ee6352, purple, #ee6352); */
  --gradient: linear-gradient(45deg, #34fd9c,#1eb3c7,#2F2BAD,#AD2BAD,#E42692,#F71568,#F7DB15, #F7DB15,#F7DB15);

}

@keyframes bg-animation {
  0% {background-position: left}
  100% {background-position: right}
}

body {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1.6;
  color: #333;
  background: white;
  
  /* background-size: 300%;
  background-image: var(--gradient);
  animation: bg-animation 25s infinite; */
}

.App {
  text-align: left;
}

.icon-block {
  display: flex;
  justify-content: center;
  margin-top: 60px;
}

.icon {
  height: 30px;
  margin: 0 10px;
  /* pointer-events: none; */
  opacity: 0.5;
  transition: 0.1s;
}

.icon:hover {
  opacity: 1;
}

a {
  font-family: 'Roboto', sans-serif;
  /* color: #050090; */
  color: black;
  opacity: 1;
  transition: 0.1s;
  /* text-decoration: none; */
  text-underline-offset: 2px;
  -webkit-text-decoration-color: #c0c4cc;
          text-decoration-color: #c0c4cc;
}

a:hover {
  color: #0600be;
  opacity: 1;
}

.text-link {
  margin-top: 50px;
}

.App-header {
  background-color: white;
  min-height: 100vh;
  max-width: 550px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #282c34;
}

h1 {
  font-size: 40px;
  font-family: 'Lato', sans-serif;
  background-image: var(--gradient);
  background-size: 300%;
  -webkit-background-clip: text;
          background-clip: text;
  color: transparent;
  animation: bg-animation 10s infinite alternate;
}

p {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  line-height: 1.6;
  margin-bottom: 0;
}

.no-underline {
  text-decoration: none;
}

@media (max-width: 640px) { 

.App{
  margin: 0 40px;
}

p {
  font-size: 14px;
}

a {
  font-size: 14px;
}

h1 {
  font-size: 34px;
}

}
